import http from 'utils/http';
import shouldFetchData from '../utils/shouldFetchData';

const requestTipsPending = () => ({
  type: 'REQUEST_TIPS_PENDING',
});

const failureTipsPending = () => ({
  type: 'FAILURE_TIPS_PENDING',
});

const receiveTipsPending = tips => ({
  type: 'RECEIVE_TIPS_PENDING',
  tips,
});

export const fetchTipsPending = () => async dispatch => {
  dispatch(requestTipsPending());

  try {
    const response = await http.get(`/user/tipspending`);

    dispatch(receiveTipsPending(response.data));
  } catch (error) {
    dispatch(failureTipsPending());
  }
};

export const fetchTipsPendingIfNeeded = () => (dispatch, getState) => {
  const { tipsPending } = getState();

  if (shouldFetchData(tipsPending)) {
    dispatch(fetchTipsPending());
  }
};
