import styled from 'styled-components';

const MenuTopWrapper = styled.div`
  .filler {
    padding-top: var(--menu-height);
    padding-bottom: 15px;
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }
`;

export default MenuTopWrapper;
