import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const NavItemWrapper = styled(NavLink)`
  display: flex;
  color: ${({ color }) => color || 'var(--text-color)'};
  text-decoration: none;
  align-items: center;
  gap: 20px;
  font-size: 25px;
`;
