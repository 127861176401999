import React from 'react';

import Logo from './components/Logo';
import MainMenu from './components/MainMenu';
import MobileNav from './components/MobileNav';
import Tools from './components/Tools';
import MenuTopWrapper from './styles/MenuTopWrapper';

import StyledMenu from './styles/StyledMenu';

const MenuTop = () => (
  <MenuTopWrapper>
    <div className="filler" />
    <StyledMenu>
      <div className="app-container menu-bar">
        <Logo />
        <MainMenu />
        <Tools />
      </div>
    </StyledMenu>
    <MobileNav />
  </MenuTopWrapper>
);

export default MenuTop;
