import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { toggleMobileNav } from 'store/actions/app';

import { NavItemWrapper } from '../styles/NavItemWrapper';

const NavItem = ({ to, icon, activeIcon, label, color, onClick }) => {
  const dispatch = useDispatch();

  const isActive = to && window.location.pathname.startsWith(to);

  const handleClick = () => {
    dispatch(toggleMobileNav());

    if (onClick) {
      setTimeout(() => {
        onClick();
      }, 250);
    }
  };

  return (
    <NavItemWrapper to={to || '#'} active={isActive} color={color} onClick={handleClick}>
      {isActive && activeIcon ? activeIcon : icon}
      {label}
    </NavItemWrapper>
  );
};

NavItem.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.node.isRequired,
  activeIcon: PropTypes.node,
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

NavItem.defaultProps = {
  to: undefined,
  color: undefined,
  activeIcon: undefined,
  onClick: undefined,
};

export default NavItem;
