import React from 'react';
import styled from 'styled-components';

import { ReactComponent as OpenRawIcon } from 'assets/icons/external-link.svg';
import { ClearButton } from 'components/styled';
import openDialogWindow from 'utils/openDialogWindow';

const OpenButton = styled(ClearButton)`
  padding-right: 10px;

  svg {
    fill: var(--white-white);
    width: 10px;
    height: 10px;
  }
`;

const OpenRawViewButton = () => (
  <OpenButton onClick={() => openDialogWindow('/lista-wiadomosci?raw')}>
    <OpenRawIcon />
  </OpenButton>
);

export default OpenRawViewButton;
