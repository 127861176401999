/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import fecha from 'fecha';
import moment from 'moment';

import { ReactComponent as MaterialSpinner } from 'assets/icons/material-spinner.svg';
import { ReactComponent as ReloadIcon } from 'icons/reload.svg';
import { ReactComponent as DeleteIcon } from 'icons/delete.svg';

import priceFormatter from 'utils/priceFormatter';
import parseMessage from 'utils/parseMessage';
import getUIAvatar from 'utils/getUIAvatar';
import firstUrl from 'utils/firstUrl';

import Link from 'components/Link';
import { SoundPlayButton } from 'components/SoundPlayButton';

import StyledTip from '../styles/StyledTip';
import { BlueDot } from './elements/BlueDot';
import ButtonCap from './elements/ButtonCap';

import { OrangeBigDot } from './elements/OrangeBigDot';
import { GreenBigDot } from './elements/GreenBigDot';
import { BlueDotSeparator } from './elements/BlueDotSeparator';

class Tip extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    nickname: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    paymentType: PropTypes.string,
    message: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    resendTip: PropTypes.instanceOf(Function).isRequired,
    deleteTip: PropTypes.instanceOf(Function).isRequired,
    goalTitle: PropTypes.string,
    showGoals: PropTypes.bool.isRequired,
    audioUrl: PropTypes.string,
    consumed: PropTypes.bool.isRequired,
    hideConsumedStatus: PropTypes.bool,
    isPending: PropTypes.bool,
  };

  static defaultProps = {
    paymentType: '',
    goalTitle: '',
    audioUrl: '',
    hideConsumedStatus: false,
    isPending: false,
  };

  static sourceHumanReadable(source) {
    switch (source) {
      case 'stream_elements':
        return 'StreamElements';
      case 'tipeee':
        return 'Tipeeestream';
      default:
        return '';
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      isResending: false,
      isDeleting: false,
    };
  }

  async resend() {
    const { id, resendTip } = this.props;

    this.setState({ isResending: true });

    await resendTip(id);
    this.setState({ isResending: false });
  }

  async deleteTip() {
    const { id, deleteTip } = this.props;

    this.setState({ isDeleting: true });

    await deleteTip(id);
  }

  render() {
    const {
      nickname,
      amount,
      createdAt,
      paymentType,
      message,
      source,
      goalTitle,
      showGoals,
      audioUrl,
      consumed,
      hideConsumedStatus,
      isPending,
    } = this.props;

    const { isResending, isDeleting } = this.state;

    const name = `${nickname} `;

    let isLink = null;
    if (message) {
      const url = firstUrl(message);
      if (url) {
        isLink = url;
      }
    }

    return (
      <StyledTip className={isResending || isDeleting ? 'loading' : ''}>
        <div className="box">
          <div className="donator">
            <div className="flexavatar">
              <div
                className="avatar"
                style={{ backgroundImage: `url("${getUIAvatar(nickname)}")` }}
              />
            </div>
            <p className="no-margin text-white-black ">
              <span className="separator">
                <span className="nickname text-white-black text-large">{name}</span>
                <BlueDotSeparator />
                <span className="tip-amount text-blue text-default">{priceFormatter(amount)}</span>
              </span>
              <div className="timespan google-sora-cdn">
                <time dateTime={createdAt}>
                  {fecha.format(new Date(createdAt), 'DD MMM YYYY, HH:mm')}
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  &nbsp;({moment(createdAt).fromNow()})
                </time>
              </div>
              {paymentType.length ? (
                <span className="text-medium google-sora-cdn paymentmethod">
                  Metoda płatności:&nbsp;
                  <span className="text-blue">{paymentType}</span>
                </span>
              ) : (
                <span />
              )}
              {source !== 'internal' && (
                <span className="text-medium google-sora-cdn paymentmethod">
                  Źródło:&nbsp;
                  <span className="text-blue ">{Tip.sourceHumanReadable(source)}</span>
                </span>
              )}
            </p>
          </div>
          <p className="message no-margin" style={{ overflow: 'hidden' }}>
            {goalTitle && showGoals && goalTitle !== 'Cel domyślny' && (
              <span className="goal text-medium text-blue list-block">
                Wybrano:
                <span className="text-medium text-white-black" style={{ marginLeft: 10 }}>
                  {goalTitle}
                </span>
              </span>
            )}
            <span
              className="text-medium google-sora-cdn"
              dangerouslySetInnerHTML={{ __html: parseMessage(message) }}
            />
            {isLink && (
              <span className="list-block google-sora-cdn">
                <Link href={isLink} target="_blank">
                  {isLink}
                </Link>
              </span>
            )}
          </p>
          <div>
            <div className="buttons">
              {!hideConsumedStatus && !isPending && (
                <>
                  {consumed && <GreenBigDot />}
                  {!consumed && <OrangeBigDot />}
                </>
              )}
              {audioUrl && <SoundPlayButton audioUrl={audioUrl} />}
              {!isPending && (
                <>
                  <button type="button" onClick={() => this.resend()}>
                    <ReloadIcon className="reload-icon" />
                  </button>
                  <button type="button" onClick={() => this.deleteTip()}>
                    <DeleteIcon />
                  </button>
                </>
              )}
            </div>
            {isPending && (
              <div className="statuswrap">
                <ButtonCap disabled type="button" background="gray" noWrapper>
                  <BlueDot />
                  <span>OCZEKUJE W KOLEJCE</span>
                </ButtonCap>
              </div>
            )}
          </div>
        </div>
        {(isResending || isDeleting) && <MaterialSpinner />}
      </StyledTip>
    );
  }
}

export default Tip;
