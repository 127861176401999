import styled from 'styled-components';

const OverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 50px;
`;

export default OverlayContent;
