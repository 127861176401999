import styled from 'styled-components';

export default styled.nav`
  display: none;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: var(--menu-height);
  background: var(--main-menu-background);
  z-index: 99;
  @media (min-width: 768px) {
    display: block;
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 9px;
    background: var(--brand-green);
  }

  .logo {
    margin-right: 83px;
  }

  .menu-bar {
    display: flex;
    align-items: center;
    height: 99px;
  }
`;
