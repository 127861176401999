import debounce from 'lodash/debounce';
import { hop } from 'utils';
import http from 'utils/http';

import moment from 'moment';
import serverAlert from '../utils/serverAlert';
import shouldFetchData from '../utils/shouldFetchData';
import { updateConfig } from './configuratorsConfigs';

const requestProfile = () => ({
  type: 'REQUEST_PROFILE',
});

export const updateUserData = updatedData => ({
  type: 'UPDATE_USER_DATA',
  updatedData,
});

const patchUserProfile = (fragment = 'default') => ({
  type: 'PATCH_USER_PROFILE',
  fragment,
});

const completePatchUserProfile = () => ({
  type: 'COMPLETE_PATCH_USER_PROFILE',
});

const completeDelete = () => ({
  type: 'COMPLETE_USER_DELETE',
});

const requestTopDonators = () => ({
  type: 'REQUEST_TOP_DONATORS',
});

const receiveTopDonators = topDonators => ({
  type: 'RECEIVE_TOP_DONATORS',
  topDonators,
});

const topDonatorsDefined = topDonators => ({
  type: 'TOP_DONATORS_DEFINED',
  topDonators,
});

const topDonatorsFailure = () => ({
  type: 'TOP_DONATORS_FAILURE',
});

export const receiveUserInfo = info => ({
  type: 'RECEIVE_USER_INFO',
  info,
});

/**
 * @param {UserAccount[]} payload
 * @returns {object}
 */
export const receiveAccountsInfo = payload => ({
  type: 'RECEIVE_ACCOUNTS_INFO',
  payload,
});

export const receivePaymentMethods = paymentMethods => ({
  type: 'RECEIVE_PAYMENT_METHODS',
  paymentMethods,
});

const failureProfile = () => ({
  type: 'FAILURE_PROFILE',
});

export const failureAccountsInfo = () => ({
  type: 'FAILURE_ACCOUNTS_INFO',
});

export const failurePaymentMethods = () => ({
  type: 'FAILURE_PAYMENT_METHODS',
});

export const profileFullyFetched = () => ({
  type: 'PROFILE_FULLY_FETCHED',
});

export const fetchProfile = () => async dispatch => {
  dispatch(requestProfile());

  try {
    const [profile, pending] = await Promise.all([
      http.get('/user/profile'),
      http.get('/user/profile?pending'),
    ]);

    let { data } = profile;

    if (pending.status === 200) {
      data = {
        ...profile.data,
        pending: { ...pending.data },
      };
    }

    if (!hop(data, 'bank_number')) {
      data.bank_number = '';
    }

    if (!hop(data, 'personalNumber')) {
      data.personalNumber = '';
    }

    dispatch(updateUserData({ profile: data }));
    dispatch(fetchUserMedia());
    dispatch(profileFullyFetched());
  } catch (error) {
    dispatch(failureProfile());
  }
};

export const fetchUserMedia = () => (dispatch, getState) => {
  const {
    userData: {
      profile: { avatar, banner },
    },
  } = getState();

  if (avatar && avatar.id) {
    http
      .get(`/medium/${avatar.id}/formats`)
      .then(response =>
        dispatch(updateUserData({ profile: { avatar: { formats: response.data } } })),
      );
  }

  if (banner && banner.id) {
    http
      .get(`/medium/${banner.id}/formats`)
      .then(response =>
        dispatch(updateUserData({ profile: { banner: { formats: response.data } } })),
      );
  }
};

export const fetchUserProfileIfNeeded = () => (dispatch, getState) => {
  const { userData } = getState();

  if (shouldFetchData(userData)) {
    dispatch(fetchProfile());
  }
};

const submitChangedData = (path, data, method) => dispatch => {
  dispatch(patchUserProfile());

  return http[method](path, data)
    .then(response => {
      dispatch(completePatchUserProfile());

      return response;
    })
    .catch(error => {
      dispatch(completePatchUserProfile());

      throw error;
    });
};

export const submitChangedPassword = passwords => dispatch =>
  dispatch(
    submitChangedData(
      '/user/change-password',
      {
        current_password: passwords['current-password'],
        plainPassword: {
          first: passwords['new-password-first'],
          second: passwords['new-password-second'],
        },
      },
      'patch',
    ),
  );

export const submitChangedEmail = emails => dispatch =>
  dispatch(
    submitChangedData(
      '/user/change-email',
      {
        email: emails['new-email'],
        current_password: emails['current-password'],
      },
      'patch',
    ),
  );

export const submitChangedProfile = profileData => dispatch =>
  dispatch(submitChangedData('/user/profile', profileData, 'put'));

export const submitChangedPayPal = paypalEmail => dispatch =>
  dispatch(submitChangedData('/user/profile/paypalemail', paypalEmail, 'patch'));

export const submitChangedSocialMediaLink = socialmedialink => dispatch =>
  dispatch(submitChangedData('/user/profile/socialmedialink', socialmedialink, 'patch'));

export const submitChangedContactName = contactname => dispatch =>
  dispatch(submitChangedData('/user/profile/contactname', contactname, 'patch'));

const debouncedPaymentMethodUpdate = debounce((methodName, data) => {
  http
    .post(`/user/payment-methods/${methodName}`, data)
    .catch(() => serverAlert('Nie udało się zapisać metody płatności'));
}, 250);

export const submitChangedPaymentMethod = (methodName, field, value) => (dispatch, getState) => {
  const {
    userData: { paymentMethods },
  } = getState();

  paymentMethods[methodName][field.replace('minimalAmount', 'minimal_amount')] = value;
  dispatch(updateUserData({ paymentMethods: { [methodName]: paymentMethods[methodName] } }));

  debouncedPaymentMethodUpdate(methodName, { [field]: value });
};

export const submitDelete = data => dispatch =>
  dispatch(() => {
    dispatch({
      type: 'USER_DELETE',
    });

    return http
      .delete('/user', { data })
      .then(response => {
        dispatch(completeDelete());

        return response;
      })
      .catch(error => {
        dispatch(completeDelete());

        throw error;
      });
  });

/**
 * Wysyła kod 2FA na e-mail zalogowanego użytkownika
 *
 * @returns {function(*): Promise}
 */
export const sendEmailAuthenticatorCode = () => dispatch => http.patch('/user/2fa/email/send-code');

/**
 * Włącza weryfikację dwuetapową za pomocą e-mail. Włączenie jest potwierdzane kodem jednorazowym.
 *
 * @param confirmationCode
 * @returns {function(*): Promise}
 */
export const enableEmailAuthenticator = confirmationCode => dispatch =>
  http.patch(`/user/2fa/email/enable/${confirmationCode}`).then(() => {
    dispatch(updateUserData({ info: { email_auth_enabled: true } }));
  });

/**
 * Wyłącza weryfiację dwuetapową za pomocą e-mail.
 *
 * @returns {function(*): Promise}
 */
export const disableEmailAuthenticator = () => dispatch =>
  http.patch('/user/2fa/email/disable').then(() => {
    dispatch(updateUserData({ info: { email_auth_enabled: false } }));
  });

/**
 * Generuje kod QR dla połączenia konta użytkownika z Google Authenticator
 *
 * @returns {function(*): Promise}
 */
export const connectGoogleAuthenticator = () => dispatch => http.patch('/user/2fa/google/connect');

/**
 * Potwierdza włączenie Google Authenticator dla konta za pomocą kodu i włącza autentyfikację
 * dwuskładnikową
 *
 * @param {string} confirmationCode - kod Google Authenticator
 * @returns {function(*): Promise}
 */
export const enableGoogleAuthenticator = confirmationCode => dispatch =>
  http.patch(`/user/2fa/google/enable/${confirmationCode}`).then(() => {
    dispatch(updateUserData({ info: { google_auth_enabled: true } }));
  });

/**
 * Wyłącza Google Authenticator dla konta
 *
 * @returns {function(*): Promise}
 */
export const disableGoogleAuthenticator = () => dispatch =>
  http.patch('/user/2fa/google/disable').then(() => {
    dispatch(updateUserData({ info: { google_auth_enabled: false } }));
  });

/**
 * Przełącza status moderacji
 *
 * @returns {function(*): Promise}
 */
export const toggleModerationMode = moderationMode => dispatch => {
  dispatch(updateUserData({ info: { moderation_mode: !moderationMode } }));
  http.post('/user/toggle-moderator').catch(error => {
    // eslint-disable-next-line no-console
    console.error(error);
  });
};

export const toggleWidgetAlerts = () => (dispatch, getState) => {
  const {
    userData: { info },
  } = getState();
  dispatch(patchUserProfile('toggleAlerts'));
  http
    .patch('/user/configuration/toggle-alerts', { disabled: !info.widget_alerts_disabled })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
    })
    .finally(() => {
      dispatch(completePatchUserProfile());
    });
};

export const toggleWidgetAlertsSounds = () => (dispatch, getState) => {
  const {
    userData: { info },
  } = getState();
  dispatch(patchUserProfile('toggleAlertsSounds'));
  http
    .patch('/user/configuration/toggle-alerts-sound', {
      disabled: !info.widget_alerts_sound_disabled,
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
    })
    .finally(() => {
      dispatch(completePatchUserProfile());
    });
};

/**
 * Funkcja wysyła zapytanie PATCH do serera z prośbą o rozłączenie połączonego serwisu.
 * Obecnie obsługiwany jest tylko Facebook.
 *
 * @param {string} service - facebook/google
 * @returns {Promise}
 */
export const disconnectService = service => dispatch =>
  http.patch(`/user/${service}/disconnect`).then(response => {
    dispatch(updateUserData({ info: { [`${service}_id`]: false } }));
    dispatch(updateConfig('COUNTER_TO_END_LIVE', { twitchActivity: false }));
    dispatch(updateConfig('COUNTER_TO_END_LIVE', { twitchActivityBegin: moment().format() }));
    dispatch(updateConfig('COUNTER_TO_END_LIVE', { twitchActivityTimer: 0 }));

    return response;
  });

/**
 * Funkcja wysyła zapytanie PATCH do serera z prośbą o aktualizacje ustawień strony do wpłat napiwków.
 *
 * @param {Object} config
 * @returns {Promise}
 */
export const updateTipsPageSettings = config => dispatch => {
  dispatch(updateUserData({ profile: { ...config, theme_color: config.themeColor } }));

  return http
    .patch('/user/profile/page_settings', config)
    .then(response => {
      serverAlert('Aktualizacja danych powiodła się', 'success');

      return response;
    })
    .catch(error => {
      serverAlert('Aktualizacja danych się nie powiodła');

      throw error;
    });
};

/**
 * Funkcja wysyła zapytanie POST do serera z prośbą o synchronizacje avatara Google.
 *
 * @returns {Promise}
 */
export const syncGoogleAvatar = () => dispatch =>
  http
    .post('/user/profile/sync_google_avatar')
    .then(response => {
      dispatch(updateUserData({ profile: { google_avatar_url: response.data } }));

      return response;
    })
    .catch(error => {
      throw error;
    });

/**
 * Funkcja wysyła zapytanie PATCH do serwera z prośbą o zaktualizowanie avatara/bannera użytkownika.
 *
 * @param {string} mediumType - avatar/banner
 * @param {Object} mediumObject
 * @returns {Promise}
 */
export const updateUserMedia = (mediumType, mediumObject) => dispatch => {
  const formData = new FormData();
  formData.append('id', mediumObject.id);

  return http
    .patch(`/user/profile/${mediumType}`, { id: mediumObject.id })
    .then(response => {
      dispatch(updateUserData({ profile: { [mediumType]: mediumObject } }));
      dispatch(fetchUserMedia());
      serverAlert('Aktualizacja danych powiodła się', 'success');

      return response;
    })
    .catch(error => {
      serverAlert('Aktualizacja danych się nie powiodła');

      throw error;
    });
};

/**
 * Funkcja wysyła zapytanie DELETE do serwera z prośbą o usunięcie avatara/bannera użytkownika.
 *
 * @param {string} mediumType - avatar/banner
 * @returns {Promise}
 */
export const deleteUserMedia = mediumType => dispatch =>
  http
    .delete(`/user/profile/${mediumType}`)
    .then(response => {
      dispatch(updateUserData({ profile: { [mediumType]: false } }));

      return response;
    })
    .catch(error => {
      serverAlert('Wystąpił bład');

      throw error;
    });

export const fetchTopDonators = () => dispatch => {
  dispatch(requestTopDonators());

  http
    .get('/user/donators')
    .then(response => {
      dispatch(receiveTopDonators(response.data));

      return response;
    })
    .catch(() => {
      dispatch(topDonatorsFailure());

      serverAlert('Wystąpił bład');
    });
};

export const defineTopDonators = donators => dispatch =>
  http
    .post('/user/donators', { donators })
    .then(response => {
      dispatch(topDonatorsDefined(donators));

      return response;
    })
    .catch(error => {
      serverAlert('Wystąpił bład');

      throw error;
    });

export const removeTopDonators = () => dispatch => {
  http
    .post('/user/donators/remove')
    .then(response => {
      dispatch(topDonatorsDefined());

      return response;
    })
    .catch(error => {
      serverAlert('Wystąpił bład');

      throw error;
    });
};

export const fetchAccountsInfo = () => async dispatch =>
  http
    .get('/user/accounts')
    .then(response => {
      dispatch(receiveAccountsInfo(response.data));
      return response;
    })
    .catch(e => {
      dispatch(failureAccountsInfo());
      serverAlert('Wystąpił bład');
    });

export const fetchPaymentMethods = () => async dispatch =>
  http
    .get('/user/payment-methods')
    .then(response => {
      dispatch(receivePaymentMethods(response.data));

      return response;
    })
    .catch(() => {
      dispatch(failurePaymentMethods());

      serverAlert('Wystąpił bład podczas pobierania metod płatności');
    });

export const externalTipsSourceConnected = provider => ({
  type: 'EXTERNAL_TIPS_SOURCE_CONNECTED',
  provider,
});

export const externalTipsSourceDisconnected = () => ({
  type: 'EXTERNAL_TIPS_SOURCE_DISCONNECTED',
});

export const toggleVerifyWithBankWizard = visible => ({
  type: 'TOGGLE_VERIFY_WITH_BANK_WIZARD',
  visible,
});

export const updateUserHasPendingValidationRequest = payload => ({
  type: 'UPDATE_USER_HAS_PENDING_VALIDATION_REQUEST',
  payload,
});
