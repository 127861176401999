import styled from 'styled-components';

const MobileNavWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 1010;
  padding: 14px;
  @media (min-width: 768px) {
    display: none;
  }
`;

export default MobileNavWrapper;
