import { combineReducers } from 'redux';

import paymentMethodsConfiguration from './paymentMethodsConfiguration';
import withdrawalMethodsConfiguration from './withdrawalMethodsConfiguration';
import configuratorsBackground from './configuratorsBackground';
import speechSynthesizerVoices from './speechSynthesizerVoices';
import configuratorsConfigs from './configuratorsConfigs';
import announcements from './announcements';
import extraAnnouncements from './extraAnnouncements';
import mediaManager from './mediaManager';
import statistics from './statistics';
import templates from './templates';
import tips from './tips';
import tipsPending from './tipsPending';
import userData from './userData';
import withdrawals from './withdrawals';
import reports from './reports';
import invoices from './invoices';
import webfonts from './webfonts';
import commissions from './commissions';
import goals from './goals';
import moderators from './moderator';
import tiplist from './tiplist';
import tipsModeration from './tipsModeration';
import goalsVoting from './goalsVoting';
import latestWithdrawals from './latestWithdrawals';
import app from './app';

export default combineReducers({
  app,
  configuratorsBackground,
  speechSynthesizerVoices,
  configuratorsConfigs,
  announcements,
  extraAnnouncements,
  mediaManager,
  statistics,
  templates,
  tips,
  tipsPending,
  userData,
  withdrawals,
  reports,
  invoices,
  webfonts,
  commissions,
  paymentMethodsConfiguration,
  withdrawalMethodsConfiguration,
  goals,
  moderators,
  tiplist,
  tipsModeration,
  goalsVoting,
  latestWithdrawals,
});
