import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AlertsControlWidget from 'components/AlertsControlWidget';
import { fetchTipsPendingIfNeeded, fetchTipsPending } from 'store/actions/tipsPending';

import Tip from './Tip';

function TipsList(props) {
  const { tips, resendTip, deleteTip, moderationMode, showGoals } = props;

  const dispatch = useDispatch();

  const alertsDisabled = useSelector(state => state.userData.info.widget_alerts_disabled);
  const pendingTips = useSelector(state => state.tipsPending.tips);

  const [renderedTips, setRenderedTips] = useState([]);

  useEffect(() => {
    dispatch(fetchTipsPendingIfNeeded());
  }, []);

  useEffect(() => {
    let intervalId;

    if (alertsDisabled) {
      intervalId = setInterval(() => {
        dispatch(fetchTipsPending());
      }, 10000);
    } else if (intervalId) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [alertsDisabled]);

  useEffect(() => {
    // show only these pending tips that are not in the tips list
    const newTips = pendingTips
      .map(tip => ({ isPending: true, ...tip }))
      .filter(pendingTip => !tips.find(tip => tip.payment_id === pendingTip.payment_id));

    setRenderedTips([...newTips, ...tips]);
  }, [pendingTips, tips]);

  return (
    <div>
      {renderedTips.map(tip => {
        const {
          id,
          created_at: createdAt,
          nickname,
          email,
          message,
          amount,
          source,
          human_method_name: methodName,
          goal_title: goalTitle,
          consumed,
          audio_url: audioUrl,
          fromSocket,
          isPending,
        } = tip;

        return (
          <Tip
            key={id}
            createdAt={createdAt}
            nickname={nickname}
            email={email}
            id={id}
            message={message}
            amount={amount}
            source={source}
            resendTip={resendTip}
            deleteTip={deleteTip}
            paymentType={methodName}
            goalTitle={goalTitle}
            consumed={consumed}
            hideConsumedStatus={fromSocket}
            moderationMode={moderationMode}
            showGoals={showGoals}
            audioUrl={audioUrl}
            isPending={isPending}
          />
        );
      })}
      <AlertsControlWidget />
    </div>
  );
}

TipsList.propTypes = {
  resendTip: PropTypes.instanceOf(Function).isRequired,
  deleteTip: PropTypes.instanceOf(Function).isRequired,
  tips: PropTypes.instanceOf(Object).isRequired,
  moderationMode: PropTypes.bool.isRequired,
  showGoals: PropTypes.bool.isRequired,
};

export default TipsList;
