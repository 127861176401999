import styled from 'styled-components';
import { ClearButton } from 'components/styled';

const FooterButton = styled(ClearButton)`
  display: flex;
  align-items: center;
  font-size: 25px;
  gap: 18px;
  color: ${props => (props.color === 'red' ? 'var(--color-danger)' : 'var(--text-color)')};
`;

export default FooterButton;
