import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  background-color: var(--bg-color-main-block);
  display: grid;
  grid-template-rows: 60px calc(100dvh - 160px) 60px;
  gap: 10px;
`;

export default Overlay;
