import styled from 'styled-components';
import { ClearButton } from 'components/styled';

const BurgerButton = styled(ClearButton)`
  display: flex;
  width: 70px;
  height: 70px;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  background-color: var(--bg-color-main-block);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export default BurgerButton;
