import { LiveChatWidget } from '@livechat/widget-react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const LiveChat = () => {
  const isMobileNavOpen = useSelector(state => state.app.isMobileNavOpen);

  useEffect(() => {
    if (isMobileNavOpen) {
      window.LiveChatWidget.call('hide');
    } else {
      window.LiveChatWidget.call('minimize');
    }
  }, [isMobileNavOpen]);

  return <LiveChatWidget license="15525981" />;
};

export default LiveChat;
