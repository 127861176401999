import styled from 'styled-components';

const Bar = styled.span`
  display: block;
  width: 27px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--text-color);
`;

export default Bar;
